<template>
  <div class="cadastro">
    <Voltar />
    <h1 class="titulo">Bem vindo</h1>
    <p>Pode ficar tranquilo que seus dados estarão seguros! ;)</p>
    <form class="form" @submit.prevent="handleSubmit">
      <div>
        <label for="nome">Qual o seu nome?</label>
        <input type="text" maxlength="30" v-model="state.nome.value" required />
        <span v-if="!!state.nome.errorMessage">
          {{ state.nome.errorMessage }}
        </span>
      </div>
      <div>
        <label for="sobrenome">E o sobrenome?</label>
        <input
          type="text"
          id="sobrenome"
          maxlength="50"
          v-model="state.sobrenome.value"
          required
        />
        <span v-if="!!state.sobrenome.errorMessage">
          {{ state.sobrenome.errorMessage }}
        </span>
      </div>
      <div>
        <label for="email">Agora o seu email</label>
        <input
          type="text"
          id="email"
          maxlength="150"
          v-model="state.email.value"
          required
        />
        <span v-if="!!state.email.errorMessage">
          {{ state.email.errorMessage }}
        </span>
      </div>
      <div>
        <label for="data">Informe a sua data de nascimento </label>
        <input type="date" id="data" v-model="state.dataNascimento.value" required />
      </div>
      <div>
        <label for="cpf">DIGITE AQUI SEU CPF </label>
        <input
          type="text"
          id="cpf"
          maxlength="14"
          v-model="state.cpf.value"
          required
        />
        <span v-if="!!state.cpf.errorMessage">
          {{ state.cpf.errorMessage }}
        </span>
      </div>
      <div>
        <label for="senha">ESCOLHA SUA SENHA</label>
        <input
          type="password"
          id="senha"
          maxlength="100"
          v-model="state.senha.value"
          required
        />
        <span v-if="!!state.senha.errorMessage">
          {{ state.senha.errorMessage }}
        </span>
        <p>Escolha uma senha de no mínimo 6 caracteres</p>
      </div>
      <div>
        <label for="senha">CONFIRME SUA SENHA</label>
        <input
          type="password"
          id="confirm_senha"
          minlength="6"
          maxlength="100"
          v-model="state.confirmacaoSenha.value"
          required
        />
        <span v-if="state.confirmacaoSenha.value!=state.senha.value">
          Senha e Confirmação de Senha não são Iguais
        </span>
      </div>
      <div>
        <label for="cel">POR FIM, O SEU CELULAR</label>
        <input
          type="tel"
          id="cel"
          minlength="6"
          maxlength="100"
          v-model="state.celular.value"
          required
        />
        <span v-if="!!state.celular.errorMessage">
          {{ state.celular.errorMessage }}
        </span>
      </div>
      <div class="input-radio">
        <span>
          <input type="radio" id="termos" v-model="state.concordo.value" required />
        </span>
        <span v-if="!!state.concordo.errorMessage">
          {{ state.concordo.errorMessage }}
        </span>
        <label class="caixa-baixa alinha-esquerda" for="termos">
          <p>
            Li e concordo com os Termos de uso, Política de Privacidade e
            Regulamento, ciente de que meus dados pessoais serão utilizados
            exclusivamente para os fins dispostos nestes documentos.
          </p>
        </label>
      </div>
      <label class="caixa-baixa alinha-esquerda" v-if="state.error">
        <p v-html="state.error" style="background-color: red; padding: 5px;"></p>
      </label>
      <div class="bt">
        <button type="submit" :disabled="state.isLoading">AVANÇAR</button>
      </div>
      <div class="bt bt--discreto">
        <a  @click="() => router.push({ name: 'Login' })">Já tem conta? CLIQUE AQUI!</a>
      </div>
    </form>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useField } from 'vee-validate'
import { useToast } from 'vue-toastification'
import { validateEmptyAndLength3 } from '../../utils/validators'
import services from '../../services'
import Voltar from '../../components/Voltar'

export default {
  setup () {
    const router = useRouter()
    const toast = useToast()
    const parceiro = JSON.parse(window.localStorage.getItem('parceiro'))
    const { value: nomeValue, errorMessage: nomeErrorMessage } =
      useField('nome')

    const { value: sobrenomeValue, errorMessage: sobrenomeErrorMessage } =
      useField('sobrenome')

    const { value: emailValue, errorMessage: emailErrorMessage } =
      useField('email')

    const {
      value: dataNascimentoValue,
      errorMessage: dataNascimentoErrorMessage
    } = useField('dataNascimento')

    const { value: cpfValue, errorMessage: cpfErrorMessage } = useField('cpf')

    const { value: senhaValue, errorMessage: senhaErrorMessage } = useField(
      'senha',
      validateEmptyAndLength3
    )

    const {
      value: confirmacaoSenhaValue,
      errorMessage: confirmacaoSenhaErrorMessage
    } = useField('confirmacaoSenha', validateEmptyAndLength3)

    const { value: celularValue, errorMessage: celularErrorMessage } =
      useField('celular')

    const { value: concordoValue, errorMessage: concordoErrorMessage } =
      useField('concordo')

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      nome: {
        value: nomeValue,
        errorMessage: nomeErrorMessage
      },
      sobrenome: {
        value: sobrenomeValue,
        errorMessage: sobrenomeErrorMessage
      },
      email: {
        value: emailValue,
        errorMessage: emailErrorMessage
      },
      dataNascimento: {
        value: dataNascimentoValue,
        errorMessage: dataNascimentoErrorMessage
      },
      cpf: {
        value: cpfValue,
        errorMessage: cpfErrorMessage
      },
      senha: {
        value: senhaValue,
        errorMessage: senhaErrorMessage
      },
      confirmacaoSenha: {
        value: confirmacaoSenhaValue,
        errorMessage: confirmacaoSenhaErrorMessage
      },
      celular: {
        value: celularValue,
        errorMessage: celularErrorMessage
      },
      concordo: {
        value: concordoValue,
        errorMessage: concordoErrorMessage
      },
      error: null
    })

    async function handleSubmit () {
      try {
        state.isLoading = true
        const { data, errors } = await services.cadastro.cadastro({
          nome: state.nome.value,
          sobrenome: state.sobrenome.value,
          email: state.email.value,
          dataNascimento: state.dataNascimento.value,
          cpf: state.cpf.value,
          senha: state.senha.value,
          confirmacaoSenha: state.confirmacaoSenha.value,
          celular: state.celular.value,
          concordo: state.concordo.value,
          parceiro: parceiro
        })

        if (!errors && !data.errors) {
          window.localStorage.setItem('user', JSON.stringify(data))
          setTimeout(function () {
            window.location = '/cadastro-sms'
            // router.push({ name: 'CadastroSms' })
          }, 1500)
          state.isLoading = false
          return
        }

        console.log(errors)

        if (errors.status === 999) {
          toast.error(errors.statusText)
          state.error = errors.statusText
        }

        if (errors.status === 404) {
          toast.error('CPF e/ou Senha não encontrado')
        }

        if (errors.status === 401) {
          toast.error('CPF e/ou Senha Inválido')
        }

        if (errors.status === 400) {
          toast.error('Erro ao fazer o Login!')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        if (error != null) {
          if (error.status === 404) {
            toast.error('CPF e/ou Senha não encontrado')
          }

          if (error.status === 401) {
            toast.error('CPF e/ou Senha Inválido')
          }

          if (error.status === 400) {
            toast.error('Erro ao fazer o Login!')
          }

          if (error.status === 422) {
            toast.error(error.statusText)
          }
          console.log('error...', error)
          console.log(error.status)
          toast.error('Erro ao fazer o Cadastro!')
          state.isLoading = false
          state.hasErrors = !!error
        }
      }
    }

    return {
      router,
      state,
      handleSubmit
    }
  },
  components: { Voltar }
}
</script>
